// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules_temp/sign-in/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules_temp/sign-in/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#ScvqNHuthovFr_Sz64Vv {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: #f9fff0;\n}\n\n#ScvqNHuthovFr_Sz64Vv h3 {\n    color: #555;\n    font-size: 1.3em;\n    font-weight: normal;\n    margin-bottom: 0.8em;\n}\n", "",{"version":3,"sources":["webpack://./src/fe/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":["#main {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: #f9fff0;\n}\n\n#main h3 {\n    color: #555;\n    font-size: 1.3em;\n    font-weight: normal;\n    margin-bottom: 0.8em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ScvqNHuthovFr_Sz64Vv"
};
export default ___CSS_LOADER_EXPORT___;
