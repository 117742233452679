"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.result = void 0;
var result;
(function (result_1) {
    function error(result) {
        return result[0] !== null;
    }
    result_1.error = error;
    function ok(result) {
        return result[1] !== null;
    }
    result_1.ok = ok;
    function all(results) {
        if (results.every(result.ok)) {
            return [null, results.map(([_, data]) => data)];
        }
        else {
            const errors = results.filter(result.error).map(([error, _]) => error);
            return [errors[0], null];
        }
    }
    result_1.all = all;
})(result = exports.result || (exports.result = {}));
