"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const setting_1 = require("../../setting");
function default_1({ domain, onSignin }) {
    const googleSignIn = (0, react_1.useRef)(null);
    function init() {
        window.google.accounts.id.initialize({
            client_id: (0, setting_1.googleClientId)(domain),
            callback: (res) => {
                onSignin(res.credential);
            }
        });
        window.google.accounts.id.renderButton(googleSignIn.current, {
            theme: 'filled_blue',
            size: 'large',
        });
    }
    (0, react_1.useEffect)(() => {
        const h = setInterval(() => {
            if (window.google) {
                clearInterval(h);
                init();
            }
        }, 500);
    }, []);
    return React.createElement("div", { ref: googleSignIn });
}
exports.default = default_1;
