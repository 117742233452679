"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const client_1 = require("react-dom/client");
const ramda_1 = require("ramda");
const google_sign_in_1 = require("./google-sign-in");
const result_1 = require("../lib/result");
const api = require("./api");
const css = require('./index.css').default;
function Root() {
    const [domain, setDomain] = (0, react_1.useState)(null);
    const [user, setUser] = (0, react_1.useState)(null);
    const formRef = (0, react_1.useRef)(null);
    const urlParams = new URLSearchParams(window.location.search);
    const loginUrl = urlParams.get('loginUrl');
    const others = (0, ramda_1.difference)(Array.from(urlParams.keys()), ['loginUrl']);
    (0, react_1.useEffect)(() => {
        api.domain.get().then(setDomain);
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        if (user) {
            if (loginUrl && domain && allowHost(domain, new URL(loginUrl).hostname)) {
                (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.submit();
            }
        }
    }, [(user === null || user === void 0 ? void 0 : user.data) || null, domain]);
    if (user) {
        return (React.createElement("div", { id: css.main },
            React.createElement("h3", null, "\u60A8\u5DF2\u767B\u5165"),
            React.createElement("form", { ref: formRef, method: 'post', encType: 'multipart/form-data', action: loginUrl || '' },
                React.createElement("input", { type: 'hidden', name: 'user', value: JSON.stringify(user) }),
                (() => {
                    return others.map(key => React.createElement("input", { type: 'hidden', name: key, value: urlParams.get(key) || '' }));
                })())));
    }
    else if (domain !== null) {
        return (React.createElement("div", { id: css.main },
            React.createElement("h3", null, "\u6B61\u8FCE\u4F7F\u7528\uFF0C\u8ACB\u5148\u767B\u5165"),
            React.createElement(google_sign_in_1.default, { domain: domain, onSignin: (idToken) => __awaiter(this, void 0, void 0, function* () {
                    const res = yield api.user.get(idToken);
                    if (result_1.result.ok(res)) {
                        const [_, user] = res;
                        setUser(user);
                    }
                }) })));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
}
function allowHost(domain, host) {
    return host.endsWith(`${domain}.com.tw`) || host === 'localhost';
}
const root = (0, client_1.createRoot)(document.getElementById('root'));
root.render(React.createElement(Root, null));
