"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.domain = exports.user = void 0;
const utils_1 = require("../lib/utils");
const dev = new URL(location.href).port === '8080';
const base = dev ? `http://localhost:3000/api` : '/api';
var user;
(function (user) {
    function get(idToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = new URLSearchParams();
            params.append('idToken', idToken);
            const res = yield (yield fetch(`${base}/user?${params.toString()}`)).json();
            if (res.error) {
                return [res.error, null];
            }
            else {
                (0, utils_1.assert)(res.data !== null, '');
                return [null, res.data];
            }
        });
    }
    user.get = get;
})(user = exports.user || (exports.user = {}));
var domain;
(function (domain) {
    function get() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`${base}/domain`);
            if (res.status === 204) {
                return null;
            }
            else {
                return yield res.text();
            }
        });
    }
    domain.get = get;
})(domain = exports.domain || (exports.domain = {}));
